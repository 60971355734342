import React from 'react';
import style from './style.module.css';
import CurrentDeployment from '../CurrentDeployment';
import TotalCash from '../TotalCash';

function UserStats() {
	return (
		<section className={style.UserStats}>
			<CurrentDeployment />
			<TotalCash />
		</section>
	);
}

export default UserStats;