import React, { useContext } from 'react';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';
import DataContext from '../../context/DataProvider';
import '../../App.css';
import style from './style.module.css';
import Home from '../../pages/Home';
import Deployments from '../../pages/Deployments';
import Payouts from '../../pages/Payouts';
import SpecialOffer from '../../pages/SpecialOffer';
import Personal from '../../pages/Personal';
import Login from '../../pages/Login';
import Chat from '../Chat';
import BitrixChatWidget from '../BitrixChatWidget';
import CloseIcon from '../../images/icons/close.svg';

function Main() {
	const location = useLocation();

	const { data, loading, error } = useContext(DataContext);

	if (loading) {
		return <p>Загрузка...</p>;
	}

	// if (error) {
	// 	return <p>Ошибка: {error}</p>;
	// }

	const isHomePage = location.pathname === '/';
	const isNotHomePage = location.pathname !== '/' && location.pathname !== '/login';

	const TITLE_MAP = {
		"/": "Булах Георгий Александрович",
		"/deployments": "Мои смены",
		"/payouts": "Выплаты",
		"/special-offer": "Gradus Акции",
		"/personal": "Личные данные",
		"/login": "Вход"
	}

	return (
		<main className={`${style.Main} ${'container'}`}>
			{
				isNotHomePage
				&&
				<NavLink to="/" className={style.CloseSectionBtn}>
					<img
						src={CloseIcon} alt="Close icon" className={style.CloseIcon}
					/>
				</NavLink>
			}
			<h1 className={style.MainTitle}>{TITLE_MAP[location.pathname]}</h1>
			<Routes>
        <Route path="/" element={<Home />} />
        <Route path="/deployments" element={<Deployments />} />
        <Route path="/payouts" element={<Payouts />} />
        <Route path="/special-offer" element={<SpecialOffer />} />
        <Route path="/personal" element={<Personal />} />
        <Route path="/login" element={<Login />} />
      </Routes>
			{
				isHomePage
				&&
				<BitrixChatWidget />
			}
		</main>
	);
}

export default Main;