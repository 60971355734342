import React, { createContext, useState, useEffect } from 'react';

const DataContext = createContext();

export const DataProvider = ({children}) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchData = async () => {
		try {
			const response = await fetch('');
			if (!response.ok) {
				throw new Error("Ошибка при загрузке данных");
			}
			const result = await response.json();
			setData(result);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<DataContext.Provider value={{ data, loading, error }}>
			{children}
		</DataContext.Provider>
	);
};

export default DataContext;