import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../App.css';
import style from './style.module.css';
import Logo from '../../images/logo.svg';
import LogOutIcon from '../../images/icons/log_out.svg'

function Header() {
	const location = useLocation();

	const isHomePage = location.pathname === '/';

	return (
		<header className={`${style.Header} ${`container`}`}>
			<img
        className={style.Logo}
        src={Logo}
        alt="Gradus Logo"
      />

			{
				isHomePage
				&&
				<NavLink to="/login" className={style.LogOutBtn}>
					<img
						className={style.LogOutIcon}
						src={LogOutIcon}
						alt="LogOut"
					/>
				</NavLink>
			}
		</header>
	);
}

export default Header;