import React from 'react';
import style from './style.module.css';
import AngleIcon from '../../images/icons/angle_right.svg'

function Payout() {
	return (
		<article className={style.Article}>
			<details className={style.Details}>
				<summary className={style.Summary}>
					<h2 className={style.Title}>FM logistic</h2>
					<p className={style.Period}>Период: с <time dateTime='' className={style.Date}>05.01.1999</time> - по <time dateTime='' className={style.Date}>20.01.1999</time></p>
					<span className={style.Payout}>Выплата: <span className={style.Total}>75542.00&#8381;</span></span>
					<span className={style.Award}>Премия: <span className={style.Total}>+1932.00&#8381;</span></span>
					<span className={style.ToggleCaption}>
						<img
							src={AngleIcon}
							alt='Angle icon'
							className={style.AngleIcon}
						/>
					</span>
				</summary>
				<ul className={style.List}>
					<li className={style.ListItem}>
						<div className={style.MotivationContainer}>
							<h3 className={style.Title}>Компенсация проезда</h3>
							<span className={style.Total}>1932.00&#8381;</span>
						</div>
					</li>

					<li className={style.ListItem}>
						<div className={style.MotivationContainer}>
							<h3 className={style.Title}>Компенсация проезда</h3>
							<span className={style.Total}>1932.00&#8381;</span>
						</div>
					</li>
				</ul>
			</details>
		</article>	
	);
}

export default Payout;