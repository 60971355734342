export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
      navigator.serviceWorker.register(swUrl)
        .then(registration => {
          console.log('Service Worker зарегистрирован с областью:', registration.scope);
        })
        .catch(error => {
          console.error('Ошибка при регистрации Service Worker:', error);
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      });
  }
}