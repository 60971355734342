import React from 'react';
import style from './style.module.css';
import GiftIcon from '../../images/icons/giftOffer.svg';
import CupIcon from '../../images/icons/cup.svg';

function Offer() {
	return (
		<article className={style.Article}>
			<img
				src={GiftIcon}
				alt="Gift icon"
				className={style.GiftIcon}
			/>
			<h2 className={style.Title}>Приведи друга!</h2>
			<p className={style.Text}>Выполняйте условия акции и получите примию к выплате от <span className={style.Snippet}>Gradus</span>!</p>
		</article>
	);
}

export default Offer;