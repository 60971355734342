import React from 'react';
import style from './style.module.css';
import AngleIcon from '../../images/icons/angle_right.svg'

function Deployment() {
	return (
		<article className={style.Article}>
			<details className={style.Details}>
				<summary className={style.Summary}>
					<h2 className={style.Title}>FM logistic</h2>
					<h3 className={style.Subtitle}>Грузчик</h3>
					<p className={style.Period}>с <time dateTime='' className={style.Date}>05.01.1999</time> - по <time dateTime='' className={style.Date}>20.01.1999</time></p>
					<span className={style.Salary}>Общая сумма:<span className={style.Total}>75542.00&#8381;</span></span>
					<span className={style.CounterShift}>
						Кол-во смен:
						<span className={style.Counter}>
							<span className={style.CurrentShift}>15</span>
							/
							<span className={style.TotalShift}>20</span>
						</span>
					</span>
					<span className={style.ToggleCaption}>
						<img
							src={AngleIcon}
							alt='Angle icon'
							className={style.AngleIcon}
						/>
					</span>
				</summary>
				<ol className={style.List}>
					<li className={style.ListItem}>
						<div className={style.ShiftContainer}>
							<time dateTime='' className={style.Date}>05.01.1950</time>
							<span className={style.Pay}>260руб/ч</span>
							<span className={style.Worked}>11ч</span>
							<span className={style.Total}>2860.00&#8381;</span>
						</div>
					</li>

					<li className={style.ListItem}>
						<div className={style.ShiftContainer}>
							<time dateTime='' className={style.Date}>05.01.1950</time>
							<span className={style.Pay}>260руб/ч</span>
							<span className={style.Worked}>11ч</span>
							<span className={style.Total}>2860.00&#8381;</span>
						</div>
					</li>
				</ol>
			</details>
		</article>	
	);
}

export default Deployment;