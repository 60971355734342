import React from 'react';
// import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import Avatar from '../../images/avatar.svg'

function Chat() {
  return (
    // <FloatingWhatsApp
		// 	phoneNumber='+79175403715'
		// 	accountName='GradusHelp'
		// 	statusMessage='Онлайн'
		// 	chatMessage='Здравствуйте, задайте ваш вопрос'
		// 	avatar={Avatar}
		// 	notificationStyle={{display: 'none'}}
		// />
		(function(w,d,u){
			var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
			var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
		})(window,document,'https://cdn-ru.bitrix24.ru/b21386792/crm/site_button/loader_2_0d8usm.js')
  );
};

export default Chat;
