import React from 'react';
import '../../App.css';
import style from './style.module.css';
import Payout from '../../components/Payout';

function Payouts() {
	return (
		<section>
			<ul className={style.List}>
				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>

				<li className={style.ListItem}>
					<Payout className={style.Payout} />
				</li>
			</ul>
		</section>
	);
}

export default Payouts;