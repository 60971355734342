import React from 'react';
import '../../App.css';
import style from './style.module.css';
import PersonalData from '../../components/PersonalData';

function Personal() {
	return (
		<>
			<PersonalData />
		</>
	);
}

export default Personal;