import React from 'react';
import '../../App.css';
import style from './style.module.css';
import UserStats from '../../components/UserStats';
import Navigation from '../../components/Navigation';

function Home() {
	return (
		<>
			<UserStats />
			<Navigation />
		</>
	);
}

export default Home;