import React from 'react';
import style from './style.module.css';

function TotalCash() {
	return (
		<article className={style.Article}>
			<h2 className={style.h2}>Общая сумма:</h2>
			<h3 className={style.h3}>15542.00₽</h3>
		</article>
	);
}

export default TotalCash;