import React from 'react';
import style from './style.module.css';

function CurrentDeployment() {
	return (
		<article className={style.Article}>
			<h2 className={style.h2}>Текущая вахта:</h2>
			<h3 className={style.h3}>FM logistic</h3>
		</article>
	);
}

export default CurrentDeployment;