import React from 'react';
import '../../App.css';
import style from './style.module.css';
import parentStyle from '../../components/Main/style.module.css';
import "react-phone-number-input/style.css"
import {useForm, Controller} from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

function Login() {
	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm();

	const navigate = useNavigate();

	const onSubmit = (data) => {
		console.log(data);
		navigate('/');
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={`${parentStyle.Login} ${style.Login}`} noValidate>
			<label className={`${style.PhoneInput} -fadeOnly`}>
				Телефон:
				<Controller
					name="phone"
					control={control}
					rules={{
						validate: (value) => isValidPhoneNumber(value) || "Неверный номер телефона"
					}}
					render={({ field: { onChange, value } }) => (
						<PhoneInput
							value={value}
							onChange={onChange}
							defaultCountry="RU"
							international="true"
							withCountryCallingCode="true"
							type="tel"
							className={style.Input}
							placeholder='+7 999 999-99-999'
						/>
					)}
				/>
			</label>
			{errors["phone"] && (
				<p className="error-message">{errors["phone"].message}</p>
			)}
			<button className={style.Btn}>Войти</button>
		</form>
	);
}

export default Login;