import React from 'react';
import '../../App.css';
import style from './style.module.css';
import Offer from '../../components/Offer';

function SpecialOffer() {
	return (
		<section>
			<ul className={style.List}>
				<li>
					<Offer />
				</li>

				<li>
					<Offer />
				</li>
			</ul>
		</section>
	);
}

export default SpecialOffer;