import React from 'react';
import '../../App.css';
import style from './style.module.css';
import Deployment from '../../components/Deployment';

function Deployments() {
	return (
		<section className={Deployments}>
			<ul className={style.List}>
				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>

				<li className={style.ListItem}>
					<Deployment className={style.Deployment} />
				</li>
			</ul>
		</section>
	);
}

export default Deployments;