import React, { useEffect } from 'react';

const BitrixChatWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn-ru.bitrix24.ru/b21386792/crm/site_button/loader_2_0d8usm.js';
    script.async = true;
    // Добавляем скрипт в head или тело документа
    document.body.appendChild(script);

    // Очистка скрипта при размонтировании компонента (опционально)
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Пустой массив зависимостей, чтобы скрипт выполнялся только при первом монтировании

  return null; // Компонент не должен отображать ничего визуально
};

export default BitrixChatWidget;
