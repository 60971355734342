import React from 'react';
import style from './style.module.css';

function PersonalData() {
	return (
		<section className={style.PersonalData}>
			<ul className={style.List}>
				<li className={style.ListItem}>
					<h2 className={style.Title}>ФИО</h2>
					<p className={style.Text}>Булах Георгий Александрович</p>
				</li>

				<li className={style.ListItem}>
					<h2 className={style.Title}>Дата рождения</h2>
					<p className={style.Text}>01.01.1900</p>
				</li>

				<li className={style.ListItem}>
					<h2 className={style.Title}>Пол</h2>
					<p className={style.Text}>Мужской</p>
				</li>

				<li className={style.ListItem}>
					<h2 className={style.Title}>Номер телефона</h2>
					<p className={style.Text}>+7 (777) 777-77-77</p>
				</li>
			</ul>
		</section>
	);
}

export default PersonalData;