import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './style.module.css';
import ListIcon from '../../images/icons/list.svg';
import CreditCardIcon from '../../images/icons/credit_card.svg';
import GiftIcon from '../../images/icons/gift.svg';
import UserIcon from '../../images/icons/user.svg';
import AngleRightIcon from '../../images/icons/angle_right.svg';

function Navigation() {
	return (
		<nav className={style.Navigation}>
			<ul className={style.List}>
				<li className={style.ListItem}>
					<NavLink to="/deployments" className={style.NavigationLink}>
						<img src={ListIcon} alt="List icon " className={style.NavigationIcon} />
						<h2 className={style.NavigationTitle}>Мои смены</h2>
						<img src={AngleRightIcon} alt="Angle right icon" className={style.NavigationAngleRight} />
					</NavLink>
				</li>
				<li className={style.ListItem}>
					<NavLink to="/payouts" className={style.NavigationLink}>
						<img src={CreditCardIcon} alt="Credit card icon" className={style.NavigationIcon} />
						<h2 className={style.NavigationTitle}>Выплаты</h2>
						<img src={AngleRightIcon} alt="Angle right icon" className={style.NavigationAngleRight} />
					</NavLink>
				</li>
				<li className={style.ListItem}>
					<NavLink to="/special-offer" className={style.NavigationLink}>
						<img src={GiftIcon} alt="Gift icon" className={style.NavigationIcon} />
						<h2 className={style.NavigationTitle}>Gradus Акции</h2>
						<img src={AngleRightIcon} alt="Angle right icon" className={style.NavigationAngleRight} />
					</NavLink>
				</li>
				<li className={style.ListItem}>
					<NavLink to="/personal" className={style.NavigationLink}>
						<img src={UserIcon} alt="User icon" className={style.NavigationIcon} />
						<h2 className={style.NavigationTitle}>Личные данные</h2>
						<img src={AngleRightIcon} alt="Angle right icon" className={style.NavigationAngleRight} />
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}

export default Navigation;